import React from "react";
import { Link } from "react-router-dom";
import Contact from "../Pages/Contact";
import OurWork from "../Pages/OurWork";

function Navbar() {
  return (
    <div>
      <div className="App-Navbar">
        {" "}
        <Link className="App-Navbar-Link" to={"/OurWork"} element={<Contact />}>
          REALISATIONS
        </Link>
        <Link className="link" to={"/"} element={<OurWork />}>
          <img src={"/Logo.png"} alt="logo" width={75} height={75} />
        </Link>{" "}
        <Link className="App-Navbar-Link" to={"/contact"} element={<OurWork />}>
          CONTACT
        </Link>{" "}
      </div>
    </div>
  );
}

export default Navbar;
