import React from "react";
import "../App.css";

function ProjectCard({ img, title, link }) {
  return (
    <div className="project-card">
      <a
        href={link}
        target="_blank"
        rel="noreferrer noopener"
      >
        <div className="project-image">
          <img src={img} alt=""></img>
        </div>

        <div className="project-title">{title}</div>
      </a>
    </div>
  );
}

export default ProjectCard;
