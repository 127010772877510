import React from "react";
import { Link } from "react-router-dom";
import SocialMedias from "../Components/SocialMedias";

function Home() {
  return (
    <div>
      <div className="Home">
        {" "}
        <div className="App-Card">
          <img
            className="logo-banner"
            src={"/Logo-Banner.png"}
            alt="logo"
            width={250}
            height={"auto"}
          />
          <h1>Votre Projet. Notre Solution.</h1>
          <h2>Société de service en informatique</h2>

          <button className="OurWork-Button">
            <Link className="OurWork-Button-Link" to="/OurWork">
              Nos Réalisations {">"}
            </Link>
          </button>
        </div>
        <div className="App-Card">
          <div className="Contact-container">
            <img
              className="Contact-bg"
              src={"/right-bg-image.png"}
              alt="logo"
            />
            <button className="Contact-Button">
              <Link className="Contact-Button-Link" to="/Contact">
                Contact {">"}
              </Link>
            </button>
          </div>
        </div>
      </div>

      <div className="Social-Medias-Div">
        <SocialMedias className="SocialMedias" />
      </div>
    </div>
  );
}

export default Home;
