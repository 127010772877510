import axios from "axios";
import { React, useState, useEffect } from "react";
import { googleLogout, useGoogleLogin } from "@react-oauth/google";
import UpdateDbForm from "../Components/UpdateDbForm";

import "./Admin.css";

function Admin() {
  const [user, setUser] = useState([]);
  const [profile, setProfile] = useState(null);

  const [projects, setProjects] = useState([]);
  const [services, setServices] = useState([]);

  const fetchData = (url, type) => {
    axios
      .get(url)
      .then((response) => {
        type === "project"
          ? setProjects(response.data)
          : setServices(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    fetchData(`${process.env.REACT_APP_BACKEND_URL}/getProjects`, "project");
    fetchData(`${process.env.REACT_APP_BACKEND_URL}/getServices`, "service");
  }, []);

  const login = useGoogleLogin({
    onSuccess: (codeResponse) => {
      setUser(codeResponse);
    },
    onError: (error) => console.log("Login Failed:", error),
  });

  const logOut = () => {
    googleLogout();
    setProfile(null);
  };

  const getProfile = (user) => {
    if (user.length !== 0) {
      axios
        .get(`${process.env.REACT_APP_GOOGLE_URL_API}${user.access_token}`, {
          headers: {
            Authorization: `Bearer ${user.access_token}`,
            Accept: "application/json",
          },
        })
        .then((res) => {
          setProfile(res.data);
          localStorage.setItem("user", user.access_token);
        })
        .catch((err) => console.log(err));
    }
  };

  useEffect(() => {
    if (user) {
      getProfile(user);
    }
  }, [user]);

  return (
    <>
      {" "}
      {profile ? (
        <div className="admin-page">
          <button className="logout" onClick={() => logOut()}>
            Log Out{" "}
          </button>

          <div className="section">
            <UpdateDbForm title={"Add a Project"} endpoint={"addProject"} />

            {projects.map((project) => (
              <ul>
                <li key={project.id}>{project.title}</li>
              </ul>
            ))}
          </div>

          <div className="section">
            <UpdateDbForm title={"Add a Service"} endpoint={"addService"} />
            {services.map((service) => (
              <ul>
                <li key={service.id}>{service.title}</li>
              </ul>
            ))}
          </div>
        </div>
      ) : (
        <button className="login" onClick={() => login()}>
          Sign in with Google 🚀{" "}
        </button>
      )}
    </>
  );
}

export default Admin;
