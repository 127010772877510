import { useForm } from "react-hook-form";
import axios from "axios";
import { React, useState } from "react";

function UpdateDbForm({ title, endpoint }) {
  const { register, handleSubmit } = useForm();

  const [image, setImage] = useState("");

  const onSubmit = (d) => {
    fetchData(d);
  };

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const uploadImage = async (event) => {
    const file = event.target.files[0];
    const base64 = await convertToBase64(file);
    setImage(base64);
  };

  const fetchData = async (data) => {
    try {
      await axios.put(`${process.env.REACT_APP_BACKEND_URL}/${endpoint}`, {
        title: data.title,
        link: data.link,
        img: image,
      });
    } catch (err) {
      console.log("failed");
    }
  };

  return (
    <div className="UpdateDbForm">
      {title}
      <form onSubmit={handleSubmit(onSubmit)}>
        <label>
          Title: <input {...register("title")} />
        </label>
        <label>
          Link: <input {...register("link")} />
        </label>
        <label>
          Image:{" "}
          <input className="image-upload" onChange={uploadImage} type="file" />
        </label>

        {endpoint === "addProject" ? (
          <input className="submit" type="submit" value="Add a Project" />
        ) : endpoint === "addService" ? (
          <input className="submit" type="submit" value="Add a Service" />
        ) : (
          ""
        )}
      </form>
    </div>
  );
}

export default UpdateDbForm;
