import React, { useRef } from "react";
import emailjs from "@emailjs/browser";

function Contact() {
  const formData = useRef();

  const onSubmit = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_f3m3q0m",
        "template_lq5tu4b",
        formData.current,
        "oJuArrZTr2BhKvnBI"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <div className="contact-form">
      <form ref={formData} onSubmit={onSubmit}>
        <input type="text" name="name" placeholder="Name" />
        <input type="text" name="email" placeholder="E-mail" />
        <textarea
          className="submit-message"
          name="message"
          type="text"
          placeholder="Message"
        />
        <input className="submit-button" type="submit" />
      </form>
    </div>
  );
}

export default Contact;
