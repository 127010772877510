import React, { useState, useEffect } from "react";
import ProjectCard from "../Components/ProjectCard";
import axios from "axios";

function OurWork() {
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/getProjects`)
      .then((response) => {
        setProjects(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  return (
    <div className="projects">
      
        {projects.map((project) => (
          <ProjectCard
            link={project.link}
            img={project.img}
            title={project.title}
          />
        ))}
    </div>
  );
}

export default OurWork;
