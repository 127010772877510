import "./App.css";
import Navbar from "../src/Components/Navbar.js";
import Home from "../src/Pages/Home.js";
import Contact from "./Pages/Contact.js";
import OurWork from "./Pages/OurWork.js";
import Admin from "./Pages/Admin.js";
import { GoogleOAuthProvider } from "@react-oauth/google";

import { Routes, Route, Link } from "react-router-dom";
function App() {
  return (
    <div className="App">
      <header>
        <Navbar />
      </header>
      <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
        <div className="App-body">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/Contact" element={<Contact />} />
            <Route path="/OurWork" element={<OurWork />} />

            <Route path="/Admin" element={<Admin />} />
          </Routes>
        </div>
      </GoogleOAuthProvider>

      <footer className="App-footer">
        All Rights Reserved&nbsp; | &nbsp;&nbsp;
        <Link
          className="link"
          to={"https://www.assirem-dev.com"}
          target="_blank"
          rel="noreferrer noopener"
        >
          Assirem DEV
        </Link>
        &nbsp;@{new Date().getFullYear()}
      </footer>
    </div>
  );
}

export default App;
